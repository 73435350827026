<template>
  <v-layout column>
    <v-layout v-if="!disabled && !hideBody" wrap>
      <NewDrawingButton
        v-if="fieldData.length == 0"
        :loading="loading"
        :owner="owner"
        :singleType="singleType"
        :editorMode="isScarsDrawing && 'uro'"
        :name="name"
        @new="onEdit"
        single
      ></NewDrawingButton>
      <DrawingPreviewButton
        v-if="fieldData.length > 0"
        :value="fieldData[0]"
        :session="session"
        :patient="patient"
        :owner="owner"
        :name="name"
        :editorMode="isScarsDrawing && 'uro'"
        @edit="onEdit"
        @remove="onRemove"
        single
      ></DrawingPreviewButton>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :session="session"
      wrap
      class="my-3"
      hideEdit
    >
      <v-layout class="ma-2" justify-center>
        <NewDrawingButton
          v-if="fieldData.length == 0"
          :loading="loading"
          :owner="owner"
          :singleType="singleType"
          :editorMode="isScarsDrawing && 'uro'"
          :name="name"
          @new="onShowEditDialog"
          single
        ></NewDrawingButton>
        <DrawingPreviewButton
          v-if="fieldData.length > 0"
          :value="fieldData[0]"
          :session="session"
          :patient="patient"
          :owner="owner"
          :name="name"
          :editorMode="isScarsDrawing && 'uro'"
          @edit="onShowEditDialog"
          single
        ></DrawingPreviewButton>
      </v-layout>
    </DisabledFieldWrapper>
    <EditFieldDialog
      v-if="showEditDialog"
      :value.sync="showEditDialog"
      :field.sync="currentEditField"
      :name="name"
      :session="session"
      @edited="onEdited"
    >
    </EditFieldDialog>
  </v-layout>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import PatientService from "@/services/patient.service";
import {
  FIELD_TYPES,
  SINGLE_DRAWING_FIELDS,
} from "@/constants/patientcard/fields/types";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showEditDialog: false,
      currentEditField: null,
    };
  },
  computed: {
    fieldData() {
      if (!this.field.data || !this.field.data.drawings) {
        return [];
      } else {
        return this.field.data.drawings;
      }
    },
    singleType() {
      if (SINGLE_DRAWING_FIELDS.includes(this.field.field_type)) {
        return this.field.field_type;
      }
      return undefined;
    },
    isScarsDrawing() {
      return this.field.field_type == FIELD_TYPES.DRAWINGS_SCARS_FIELD;
    },
  },
  components: {
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    NewDrawingButton: () =>
      import("@/components/buttons/editor/NewDrawingButton"),
    DrawingPreviewButton: () =>
      import("@/components/buttons/editor/DrawingPreviewButton"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    EditFieldDialog: () =>
      import("@/components/popups/patientcard/EditFieldDialog"),
  },
  methods: {
    onShowEditDialog(data) {
      this.currentEditField = JSON.parse(JSON.stringify(this.field));
      this.currentEditField.data = data;

      this.showEditDialog = true;
    },
    onEdit(data) {
      let drawing = JSON.parse(JSON.stringify(this.field));
      drawing.data = data;

      this.beforeRequest();
      PatientService.updateHistoryField(
        this.field.name,
        drawing
      )
        .then((reply) => {
          this.onEdited(reply);
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.reset();
          this.handleError("Coś poszło nie tak.");
        });
    },
    onEdited(reply) {
      this.$set(this.field, "data", reply.data);
      this.$set(this.field, "reason", reply.reason);
      this.$set(this.field, "ip", reply.ip);
      this.$set(this.field, "modified", reply.modified);
      this.$set(this.field, "edited_by", reply.edited_by);
    },
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
  },
};
</script>